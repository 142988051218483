.blogList-css-img img {
    width: 82px !important;
    height: 82px !important;
}

.blogList-css-check input[type="checkbox"] {
    font-size: 15px;
    /* appearance: none; */
    visibility: hidden;
}

.blogList-css-check input[type="checkbox"][checked] {
    font-size: 15px;
    visibility: hidden;
    appearance: none;
}

.blogList-css-check input[type="checkbox"][checked]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.blogList-css-check input[type=checkbox] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    -moz-border-colors: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    -moz-appearance: none;
    appearance: none;
}

.blogList-css-check input[type=checkbox][disabled] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}


.blogList-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.blogList-css-check input[type=checkbox]:not(:checked):after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.blogList-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    /* display: unset; */
    font-weight: bold;
}

/* css-input-checkbox */

.update-css-check input[type="checkbox"] {
    font-size: 18px;
    visibility: hidden;
}

.update-css-check input[type="checkbox"][checked] {
    font-size: 16px;
    visibility: hidden;
    appearance: none;
}

.update-css-check input[type="checkbox"][checked]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.update-css-check input[type=checkbox] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    -moz-border-colors: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    -moz-appearance: none;
    appearance: none;
}

.update-css-check input[type=checkbox][disabled] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}


.update-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.update-css-check input[type=checkbox]:not(:checked):after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.update-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    /* display: unset; */
    font-weight: bold;
}

/* end-css-input-checkbox */



/* css-input-checkbox */

.add-css-check input[type="checkbox"] {
    /* outline: 1px solid #fff !important; */
    font-size: 18px;
    visibility: hidden;
    /* -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -ms-appearance: textfield;
    -o-appearance: textfield; */

}

.add-css-check input[type="checkbox"][checked] {
    /* outline: 1px solid #fff !important; */
    font-size: 16px;
    visibility: hidden;
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;*/
    appearance: none;
}

.add-css-check input[type="checkbox"][checked]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */


}

.add-css-check input[type=checkbox] {
    content: " ";
    /* background-color: #fff; */
    display: inline-block;
    color: #04BF16;
    -moz-border-colors: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    /* border: 1px solid #04BF16; */
    /* padding: 0 3px; */
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    /* -ms-appearance: none;
    -o-appearance: none; */
    appearance: none;


}

.add-css-check input[type=checkbox][disabled] {
    content: " ";
    /* background-color: #fff; */
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    /* border: 1px solid #04BF16; */
    /* padding: 0 3px; */
    /* margin: 5px; */

    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}


.add-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}

.add-css-check input[type=checkbox]:not(:checked):after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}

.add-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    /* display: unset; */
    font-weight: bold;
}

/* end-css-input-checkbox */

.blogLis-css-table .thead-dark th:nth-child(3) {
    width: 600px;
}

.blogLis-css-table .thead-dark th:nth-child(4) {
    width: 350px;
}

.blogLis-css-table .thead-dark th:nth-child(5) {
    width: 350px;
}

.blogLis-css-table .thead-dark th:nth-child(6) {
    width: 350px;
}

.blogLis-css-table .thead-dark th:nth-child(7) {
    width: 350px;
}

.mr-b-0 {
    margin-bottom: 0px;
}

.SelectTree_select__1VqCt {
    height: calc(1em + 1rem + 2px);
    padding: 0.3em 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
}