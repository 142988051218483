#fee-list-id .rdtPicker {
    top: 50px;
    right: 0;
    width: 265px;
}

#fee-list-id .rdtPicker:before,
#fee-list-id .rdtPicker:after {
    right: 10px;
    left: auto;
}

#fee-list-id .form-group {
    margin-bottom: 0px;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.stampFeeList-css-check input[type=checkbox] {
    cursor: pointer;
    font-size: 12px;
    visibility: hidden;
    /* position: absolute; */
    transform: scale(1.5);
}

.stampFeeList-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 12px;
    position: absolute;
    height: 12px;
    line-height: 12px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 1px;
    margin: 0px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}


.stampFeeList-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    /* display: unset;*/
    font-weight: bold;
}