.wrapper {
    height: calc(100vh - 100px);
    padding: 20px 0;
    overflow: hidden;
    overflow-y: auto;
}

.error {
    color: red;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 0;
}

.editArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.editArea .item {
    margin-right: 5px;
    cursor: pointer;
}

.editArea .item:last-child {
    margin-right: 0;
}

.khongduyetStt,
.choduyetStt,
.daduyetStt,
.bosungStt,
.moidangky {
    display: block;
    width: 7.5rem;
    margin: auto;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.khongduyetStt {
    border: #D9001B solid 1px;
    color: #D9001B;
    text-align: center
}

.daduyetStt {
    border: #04BF16 solid 1px;
    color: #04BF16;
    text-align: center
}

.choduyetStt{
    border: orange solid 1px;
    color: orange;
    text-align: center
}

.bosungStt{
    border: #000 solid 1px;
    color: #000;
    text-align: center
}

.moidangky{
    border: #02a7f0 solid 1px;
    color: #02a7f0;
    text-align: center
}

.editHeaderArea {
    width: 78px;
}

.searchArea {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.searchArea .searchRow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.searchArea .searchRow:last-child {
    margin-bottom: 0;
}

.searchArea .searchRow .checkBoxItem {
    margin-right: 15px;
}

.searchArea .searchRow .checkBoxItem input[type=checkbox] {
    margin-right: 10px;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.searchArea .searchRow .searchRowItem {
    width: 100%;
}

.formControl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formControl .rowItem {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
}

.formControl .rowItem .inputArea {
    width: 100%;
}

.formControl .rowItem label {
    width: 160px;
    margin-bottom: 0;
}

.formControl .rowTitle {
    width: 100%;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

@media screen and (max-width: 359px) {
    .searchArea .searchRow .checkBoxItem input[type=checkbox] {
        margin-right: 8px;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }

    .searchArea .searchRow .checkBoxItem label {
        font-size: .8rem;
    }
}