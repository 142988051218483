.SelectTree_select__1VqCt {
    height: calc(1em + 1rem + 2px);
}

/* .SelectTree_header__color {
    color: #8898aa
}

.selectBody {
    color: #8898aa;
} */

.css-select-height-button {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    /* color: #525f7f; */
    border: 0;
}

.select-height-css {
    height: 25px;
}