.css-span-server-sidebar {
    color: #f2a631;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
}

.css-margin-botton-border {
    border-bottom: 1px solid #118DEF;
    margin-top: 10px;
    width: 82%;
    margin: 0 auto;
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .w-server-sidebar {
        width: 100%;
    }
}