/* .table-css-roleList .thead-dark th:nth-child(2) {
    width: 1000px;
} */

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.Select_select__3PGnh {
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    /* border: 0; */
    transition: box-shadow .15s ease;
    border-color: #ffffff !important;
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem !important;
}

.Select_selectHeader__2sQC3 {
    height: 20px !important;
}

.form-control {
    padding: 0 0.75rem !important;
}

.UserAccount_rowItem__3xAPo {
    margin-bottom: 0px !important;
}

.css-border-input {
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

/* .Select_selectHeader__2sQC3 {
    color: #8898aa;
} */

.form-control {
    height: calc(1em + 1rem + 2px);
}

.form-control-label {
    color: #525f7f;
}