.headerNavbar {
    height: 50px;
}

.avatar {
    top: 0 !important;
    font-size: 36px;
}

.avatarBck {
    width: 36px;
    height: 36px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.updateAvaArea {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.updateAva {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%;
}

.hoverNoti :hover {
    background-color: #DDDDDD
}
