.wrapper {
    height: calc(100vh - 100px);
    padding: 20px 0;
    overflow: hidden;
    overflow-y: auto;
}

.error {
    color: red;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 0;
}

.editArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.editArea .item {
    margin-right: 5px;
    cursor: pointer;
}

.editArea .item:last-child {
    margin-right: 0;
}

.noActiveStt,
.activeStt {
    font-weight: 600;
}

.noActiveStt {
    color: red;
}

.activeStt {
    color: green;
}

.editHeaderArea {
    width: 78px;
}

.radioBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
}

.radioBox label {
    margin-bottom: 0;
    margin-left: 3px;
}

.defaultLeftSpacing {
    margin-left: 150px;
}

.treeParent {
    font-size: .9rem !important;
    /* font-weight: 700; */
}

.treeChild {
    font-size: .8rem !important;
}

.treeChild.childs {
    color: #09b2fd;
    font-weight: 600;
}

.treeChild.childsItem {
    font-weight: 500;
}

.childLine {
    font-size: 1rem;
}