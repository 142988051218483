/* .table-css-companyNotComfirm .thead-dark th:nth-child(5) {
    width: 450px;
} */

.table-css-companyNotComfirm .thead-dark th:nth-child(6) {
    width: 450px;
}

.table-css-companyNotComfirm .thead-dark th:nth-child(7) {
    width: 350px;
}

/* .table-css-companyNotComfirm .thead-dark th:nth-child(2) {
    width: 600px;
} */

.width-control-label {
    width: 210px !important;
}