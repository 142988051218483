#history .rdtPicker {
    top: 50px;
    right: 0;
    width: 265px;
}

#history .rdtPicker:before,
#history .rdtPicker:after {
    right: 10px;
    left: auto;
}

.table-css-history .thead-dark th:nth-child(2) {
    width: 200px;
}

.table-css-history .thead-dark th:nth-child(3) {
    width: 300px;
}

/* .table-css-history .thead-dark th:nth-child(4) {
    width: 800px;
} */
.form-group {
    margin-bottom: 0px !important;
}