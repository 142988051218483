.boxHeader {
    padding: 0 15px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.boxHeader .title {
    font-weight: 600;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-transform: uppercase;
}

.cardArea {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.companyAwaitHook {
    height: auto !important;
    padding-top: 12px;
}

.companyAwaitHook table {
    overflow: hidden;
    overflow-y: scroll;
    height: 128px;
}

.companyAwaitHook .spacing {
    padding: 0 15px !important;
}

.flexArea {
    display: flex;
    padding: 20px 15px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.flexRow {
    display: flex;
    width: 100%;
    padding: 0;
    margin-bottom: 1.6rem;
    justify-content: flex-start;
    align-items: center;
}

.flexRow:last-child {
    margin-bottom: 0;
}

.flexRow .item {
    width: 50%;
}

.flexRow .item:last-child {
    text-align: right;
}

.flexRow .item p {
    margin-bottom: 0;
}

.stronger {
    font-weight: 700;
}

.dashboardArea {

    /* height: 64vh; */
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    justify-content: center;
    /*background-color: #0f9b32;*/
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
}

.contentDashBoard {
    /* border: 1px solid #d1d1d1; */
    border: 1px solid;
    width: fit-content;
    padding: 8px;
    background: #fff;
    /* color: #505050; */
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    min-width: 200px;
    min-height: 65px;

}

.aligServer {
    justify-content: center;
}

.backgroundDash {
    /* background-image: url(../assets/img/BackGround/BackgroundLogin.jpg); */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px !important;
    align-items: center;
}

@media screen and (max-width: 1366px) {
    .flexRow {
        margin-bottom: .4rem;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .flexRow {
        margin-bottom: 0;
        flex-direction: column;
    }

    .flexRow .item {
        width: 100%;
    }

    .flexRow .item:last-child {
        text-align: left;
    }
}