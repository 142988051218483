.userName:not(.userName):hover {
    display: none;

}

.table-css-user-province .thead-dark th:nth-child(2) {
    width: 500px;
}

.Select_selectDisable__lLJj_ {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
}

.Select_overSelectHeight__LtYGI .Select_selectHeader__alTWk {
    height: 20px !important;
}