#field-area .hide {
    display: none;
}

.tablecs .btn-group button {
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.FieldList_rowItem__3FAqD label {
    height: 43px !important;
}

.background-color-treeParent-fieldList {
    background-color: rgb(209, 209, 209);
}

.color-text-fieldList {
    color: #000 !important;
}