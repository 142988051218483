.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.table-css-company-list-await-expired .thead-dark th:nth-child(2) {
    width: 350px;
}

.table-css-company-list-await-expired .thead-dark th:nth-child(3) {
    width: 350px;
}

.table-css-company-list-await-expired .thead-dark th:nth-child(6) {
    width: 150px;
}

.table-css-company-list-await-expired .thead-dark th:nth-child(7) {
    width: 350px;
}