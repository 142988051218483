.table-css-companyNotComfirm .thead-dark th:nth-child(5) {
    width: 150px;
}

.table-css-companyNotComfirm .thead-dark th:nth-child(6) {
    width: 450px;
}

.table-css-companyNotComfirm .thead-dark th:nth-child(7) {
    width: 350px;
}

/* .table-css-companyNotComfirm .thead-dark th:nth-child(2) {
    width: 600px;
} */
@media screen and (min-width: 360px) and (max-width: 750px) {
    .div_flex {
        display: block;
        width: 100%;
    }

    .mg-div-search {
        margin: 0;
    }

    .div_flex {
        margin-bottom: 5px;
    }

}