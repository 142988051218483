.lp-modal-image,
.lp-modal-image * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* .lp-modal-image {
    width: 100%;
} */

.lp-modal-image>.container-img>.image {
    width: 536px !important;
    height: 300px !important;
    /* object-fit: cover; */
    opacity: 1;
    transition: opacity 0.6s ease;
    -webkit-transition: opacity 0.6s ease;
    -moz-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    max-height: 500px
}

.lp-modal-image>.container-img>.image:hover {
    opacity: 0.7;
    cursor: pointer;
}

.lp-modal-image>.modal-company-verify {
    /* display: none; */
    visibility: hidden;
    position: fixed;
    z-index: 1;
    padding: 50px 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}


.lp-modal-image>.modal-company-verify>.modal-content-img {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 780px;
}

/* .lp-modal-image>.modal>.caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 780px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
} */

.lp-modal-image>.modal-company-verify>.modal-content-img {
    animation-name: zoom;
    animation-duration: 0.6s;
}

.lp-modal-image>.container-img {
    width: 100%;
    display: flex;
    display: -webkit-flex;
}

.lp-modal-image>.modal-company-verify>.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
}

.lp-modal-image>.modal-company-verify>.close:hover,
.lp-modal-image>.modal-company-verify>.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@media only screen and (max-width: 780px) {
    .lp-modal-image>.modal-company-verify>.modal-content-img {
        width: 100%;
    }
}