.box-list {
    border-radius: 6px;
    background: #fff;
    width: 100%;
    border-top: 4px solid #138bef;
}

.header-box-list {
    background: #60C3FF;
}

.header-box-list h4 {
    font-family: 600;
    font-size: 18px;
    padding: 10px 20px;
    margin: 0;
    color: #fff;
}

.body-box-list {
    padding-left: 20px;
    padding-right: 20px;
}

.info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-text span {
    width: 100%;
    /* opacity: 0.7; */
}

.mrg-10 {
    margin: 10px 0px;
}

.box-text {
    padding: 5px 0px;
}

.table-box-list thead tr th:nth-child(1) {
    width: 12% !important;
}

.table-box-list thead tr th:nth-child(4) {
    width: 20%;
}

.table-box-list-3 thead th:nth-child(1) {
    width: 65% !important;
}

.table-box-list-3 thead th {
    background: #fff;
    padding: 8px 10px;
    text-align: left;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.table-box-list thead tr th {
    background: #fff;
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.table-box-list .table-tr-box-list tr {
    border-bottom: 1px solid #000;
}

.table-tr-box-list th {
    opacity: 1;

    background: #fff;
}

/* .table-box-list .tbody tr {} */

.table-box-list tbody tr td {
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}


.table-box-list-3 tbody tr td {
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.text-td {
    /* display: block; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    width: 297px;
}

.text-td-3 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    /* opacity: 0.7; */
    text-align: left;
    color: #525f7f;
    /* width: 161px; */
}

.text-center-status {
    text-align: center;
    font-size: 11px;
    margin: 0 !important;
}

.mr-bottom {
    border-bottom: 1px solid #d1d1d1;
}

.footer-box-list {
    padding: 20px;
    text-align: right;
}

.footer-box-list a {
    font-weight: 600;
}

.header-box-list input {
    width: 60px;
    margin-right: 20px;

}

.display-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-box-list a {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 5px solid #fff !important;
}

.text-algin-img {
    text-align: center;
}

.text-algin-text {
    text-align: left;
}

.text-algin-number {
    text-align: right;
}

.text-algin-date {
    text-align: center;
}

.table-box-list tbody tr td span {
    /* -webkit-filter: blur(3%);
    filter: blur(3%);
    opacity: 0.7; */
    color: #525f7f;
}