.noActiveStt {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #000;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.activeStt {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #04BF16;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.notyetStt {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: orange;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.noActiveStt {
    background-color: #fff;
    border: 1px solid #000;
}

.notyetStt {
    background-color: #fff;
    border: 1px solid orange;
}

.activeStt {
    background-color: #fff;
    border: 1px solid #04BF16;
}

.font-size-stt {
    font-size: 11px;

}

.center-align-td {
    text-align: center;
    margin: 0;
}

.table-tr-box-list th {
    text-align: center;
}