#registered-list-id .rdtPicker {
    top: 50px;
    right: 0;
    width: 265px;
}

#registered-list-id .rdtPicker:before,
#registered-list-id .rdtPicker:after {
    right: 10px;
    left: auto;
}

#registered-list-id .form-group {
    margin-bottom: 10px;
}

.css-hover-register-list:hover {
    background-color: rgba(0, 0, 0, .075);
}

.box-shadow-input {
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
}

/* .table-css-registered-list .thead-dark th:nth-child() */