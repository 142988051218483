.Select_select__FZ4fG {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
}

.Select_selectHeader__alTWk {
    color: #525252;
}

.css-select-header {
    height: 25px !important;
}

.css-span-height {
    height: 25px !important;
}

.css-height-span-select {
    height: 25px;
}