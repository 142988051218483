.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

/* .css-margin-company-await {
    margin: 2px 0px !important;
} */

/* .css-margin-bottom-company-await {
        margin-top: 10px;
    } */

/* .CompanyListRequestExtend_rowItem__3q4mk {
    margin-bottom: 5px !important;
} */

.table-css-company-list-request-extend .thead-dark th:nth-child(2) {
    width: 300px;
}

.table-css-company-list-request-extend .thead-dark th:nth-child(3) {
    width: 300px;
}

.table-css-company-list-request-extend .thead-dark th:nth-child(6) {
    width: 150px;
}

.table-css-company-list-request-extend .thead-dark th:nth-child(7) {
    width: 350px;
}

.css-p-margin p {
    margin: 0;
}